import React, {Component} from "react";
import {withRouter} from "react-router";
import {Route, Switch} from "react-router-dom";

import APIService from "../API";
import ListUsers from '../Views/ListUsers';
import EditUser from '../Editors/EditUser'

class UserController extends Component {

  state = {
    users: [],
    isFetchingUsers: false,
  };

  componentDidMount() {
    this.getUserList();
  };

  getUserList = () => {
    this.setState({isFetchingUsers: true})
    fetch(APIService.serverUrl + "/user/list", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      credentials: "include"
    })
      .then(result => {
        return result.json();
      })
      .then(json => {
        this.setState({users: json.results})
      })
    this.setState({isFetchingUsers: false})
  };

  render = () => {

    let relativePath = this.props.match.path;

    return (
      <Switch>
        <Route
          path={relativePath}
          exact
          render={() =>
            <ListUsers
              {...this.state}
              relativePath={relativePath}
              users={this.state.users}
              isFetching={this.state.isFetchingUsers}
            />
          }
        />
        <Route
          path={relativePath + "/add"}
          render={() =>
            <EditUser
              relativePath={relativePath}
              refreshList={this.getUserList}
            />
          }
        />
        <Route
          path={relativePath + "/edit/:userId"}
          render={() =>
            <EditUser
              relativePath={relativePath}
              refreshList={this.getUserList}
            />
          }
        />
      </Switch>
    )
  };
};

export default withRouter(UserController);
