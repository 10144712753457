import React, {Component} from "react";
import {withRouter} from 'react-router';
import {Checkbox, Container, Dropdown, Form, Header, Button, Icon, Input, Label} from "semantic-ui-react";
import APIService from "../API";


class EditClient extends Component {
  state = {
    client: null,

    isActive: true,
    clientName: "",
    clientId: "",
    clientSecret: "",
    redirectUris: [],
    scopes: [],
    grants: [],

    redirectURI: "",
    availableScopes: [],
    availableGrants: [],

    isReadingClient: false,
    isUpdatingClient: false,
    isFetchingScopes: false,
    isFetchingGrants: false,
  };

  componentDidMount() {
    this.getScopes();
    this.getGrants();
    if(this.isEdit()) {
      this.readClient();
    };
  };

  isEdit = () => this.props.match.params.clientId !== undefined;

  clientId = () => this.props.match.params.clientId;

  readClient = () => {
    this.setState({isReadingClient: true});
    fetch(APIService.serverUrl + "/client/" + this.clientId(), {
      method: "GET",
      headers: {
        clientId: APIService.clientId
      },
      credentials: "include",
    })
      .then(result => {
        return result.json();
      })
      .then(json => {
        this.setState({
          client: json,
          isActive: json.isActive,
          clientName: json.clientName,
          clientId: json.clientId,
          clientSecret: json.clientSecret,
          isConfidential: json.isConfidential,
          redirectUris: json.redirectUris,
          scopes: json.scopes,
          grants: json.grants
        })
      })
    this.setState({isReadingClient: false});
  };

  updateClient = () => {
    this.setState({isUpdatingClient: true});
    fetch(APIService.serverUrl + "/client" + (this.isEdit() ? "/" + this.clientId() : ""), {
      method: this.isEdit() ? "PATCH" : "POST",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      credentials: "include",
      body: JSON.stringify({
        isActive: this.state.isActive,
        clientName: this.state.clientName,
        ...this.isEdit() && {clientId: this.state.clientId},
        ...this.isEdit() && {clientSecret: this.state.clientSecret},
        redirectUris: this.state.redirectUris,
        scopes: this.state.scopes,
        grants: this.state.grants
      })
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return null;
        }
      })
      .then((json) => {
        if (json) {
          this.props.refreshList();
          if (this.isEdit()) {
            this.readClient();
          } else {
            this.props.history.replace('/client/edit/' + json._id);
            this.readClient();
          }
        } else {
          console.log('patchClient PATCH Failed')
        }
        this.setState({isUpdatingClient: false});
      })
  };

  getScopes = () => {
    this.setState({isFetchingScopes: true});
    return APIService.listScopes({isActive: true})
      .then(json => {
        this.setState({availableScopes: json.results});
      })
      .finally(() => {
        this.setState({isFetchingScopes: false});
      })
  }

  getGrants = () => {
    this.setState({isFetchingGrants: true});
    return APIService.listGrants()
      .then(json => {
        this.setState({availableGrants: json.results});
      })
      .finally(() => {
        this.setState({isFetchingGrants: false});
      })
  }

  render = () => {

    return (
      <Container>
        <Form loading={this.state.isReadingClient}>
          <Header
            dividing
            icon='add'
            content={this.isEdit() ? 'Edit Client' : 'Add Client'}
          />

          {/*isActive Field*/}
          <Form.Field>
            <label>isActive:&nbsp;
              <Icon
                name='asterisk'
                size='small'
                color='blue'
              />
            </label>
            <Checkbox
              toggle
              id='isActive'
              name='isActive'
              disabled={this.state.isUpdatingClient}
              checked={this.state.isActive}
              onChange={(e) => {
                this.setState({isActive: !this.state.isActive})
              }}
            />
          </Form.Field>

          {/*ClientName Field*/}
          <Form.Field>
            <label>Client Name:&nbsp;
              <Icon
                name='asterisk'
                size='small'
                color='blue'
              />
            </label>
            <Input
              id='clientName'
              name='clientName'
              disabled={this.state.isUpdatingClient || this.isEdit()}
              value={this.state.clientName}
              onChange={(e) => {
                this.setState({clientName: e.target.value})
              }}
            />
          </Form.Field>

          {this.isEdit() &&
            <React.Fragment>
              {/*ClientID Field*/}
              <Form.Field>
                <label>Client ID:&nbsp;
                  <Icon
                    name='asterisk'
                    size='small'
                    color='blue'
                  />
                </label>
                <Input
                  id='clientId'
                  name='clientId'
                  disabled
                  value={this.state.clientId}
                  onChange={(e) => {
                    this.setState({clientId: e.target.value})
                  }}
                />
              </Form.Field>

              {/*Client Secret Field*/}
              <Form.Field>
                <label>Client Secret:&nbsp;
                  <Icon
                    name='asterisk'
                    size='small'
                    color='blue'
                  />
                </label>
                <Input
                  id='clientSecret'
                  name='clientSecret'
                  disabled
                  value={this.state.clientSecret}
                  onChange={(e) => {
                    this.setState({clientSecret: e.target.value})
                  }}
                />
              </Form.Field>
            </React.Fragment>
          }

          {/*Redirect URIs Field*/}
          <Form.Group style={{display: 'flex', alignItems: 'flex-end'}}>
            <Form.Field style={{marginBottom: '-3px'}}>

              <label>Redirect URIs:&nbsp;
                <Icon
                  name='asterisk'
                  size='small'
                  color='blue'
                />
              </label>

              <Input
                id='redirectURI'
                name='redirectURI'
                disabled={this.state.isUpdatingClient}
                value={this.state.redirectURI}
                onChange={(e) => {
                  this.setState({redirectURI: e.target.value})
                }}
              />
            </Form.Field>

            <Button
              // size="mini"
              color="green"
              content="Add"
              onClick={() => {
                let redirectUris = this.state.redirectUris;
                redirectUris.push(this.state.redirectURI);
                this.setState({
                  redirectUris: redirectUris,
                  redirectURI: ""
                });
                console.log("button log", this.state.redirectURI, this.state.redirectUris)
              }}
              style={{marginBottom: '2px'}}
            />

            <ul style={{marginBottom: '7px', paddingLeft: '8px'}}>
              {this.state.redirectUris.map((uri, index) => {
                return (
                  <Label basic color="blue">
                    {uri}
                    <Icon
                      name='delete'
                      color='red'
                      onClick={() => {
                        let redirectUris = this.state.redirectUris;
                        redirectUris.splice(index, 1);
                        this.setState({redirectUris: redirectUris});
                      }}
                    />
                  </Label>
                )
              })}
            </ul>
          </Form.Group>

          {/*Scopes Field*/}
          <Form.Group style={{display: 'flex', alignItems: 'flex-end'}}>
            <Form.Field style={{marginBottom: '-3px'}}>

              <label>Scopes:&nbsp;
                <Icon
                  name='asterisk'
                  size='small'
                  color='blue'
                />
              </label>

              <Dropdown
                id='scope'
                name='scope'
                selection
                multiple
                loading={this.isFetchingScopes}
                disabled={this.state.isUpdatingClient}
                options={this.state.availableScopes.map(v => ({text: v.name, value: v.name}))}
                value={this.state.scopes}
                onChange={(e, data) => {
                  this.setState({scopes: data.value})
                }}
              />
            </Form.Field>
          </Form.Group>

          {/*Grants Field*/}
          <Form.Group style={{display: 'flex', alignItems: 'flex-end'}}>
            <Form.Field style={{marginBottom: '-3px'}}>

              <label>Grants:&nbsp;
                <Icon
                  name='asterisk'
                  size='small'
                  color='blue'
                />
              </label>

              <Dropdown
                id='grant'
                name='grant'
                selection
                multiple
                loading={this.isFetchingGrants}
                disabled={this.state.isUpdatingClient}
                options={this.state.availableGrants.map(v => ({text: v, value: v}))}
                value={this.state.grants}
                onChange={(e, data) => {
                  this.setState({grants: data.value})
                }}
              />
            </Form.Field>
          </Form.Group>

          {/*Button Field*/}
          <Form.Field>
            <Button
              size='mini'
              style={{marginRight: '10px'}}
              onClick={() => {this.props.history.push("/client")}}
              loading={this.state.isUpdatingClient}
              disabled={this.state.isUpdatingClient || this.state.isReadingClient}
            >
              <Icon name='left arrow'/>
              Back
            </Button>

            <Button
                loading={this.state.isUpdatingClient}
                disabled={this.state.isUpdatingClient || this.state.isReadingClient}
                size='mini'
                color='green'
                onClick={this.updateClient}
            >
                <Icon name='right arrow' style={{marginRight: '5px', marginLeft: '0'}}/>
                {this.isEdit() ? 'Save Client' : 'Add Client'}
            </Button>
          </Form.Field>
        </Form>
      </Container>
    )
  };
};

export default withRouter(EditClient);
