import React, {Component} from "react";
import {withRouter} from "react-router";
import {Route, Switch} from "react-router-dom";

import APIService from "../API";
import ListScopes from '../Views/ListScopes';
import EditScope from '../Editors/EditScope'

class ScopeController extends Component {

  state = {
    scopes: [],
    isFetchingScopes: false,
  };

  componentDidMount() {
    this.getScopeList();
  };

  getScopeList = () => {
    this.setState({isFetchingScopes: true})
    return APIService.listScopes()
      .then(json => {
        this.setState({scopes: json.results});
      })
      .finally(() => {
        this.setState({isFetchingScopes: false});
      })
  };

  render = () => {

    let relativePath = this.props.match.path;

    return (
      <Switch>
        <Route
          path={relativePath}
          exact
          render={() =>
            <ListScopes
              {...this.state}
              relativePath={relativePath}
              clients={this.state.scopes}
              isFetching={this.state.isFetchingScopes}
            />
          }
        />
        <Route
          path={relativePath + "/add"}
          render={() =>
            <EditScope
              relativePath={relativePath}
              refreshList={this.getScopeList}
            />
          }
        />
        <Route
          path={relativePath + "/edit/:scopeId"}
          render={() =>
            <EditScope
              relativePath={relativePath}
              refreshList={this.getScopeList}
            />
          }
        />
      </Switch>
    )
  };
};

export default withRouter(ScopeController);
