
export default class APIService {
  static serverUrl = process.env.REACT_APP_SOLSYSAUTH_API_URL || "RUNTIME_REPLACE_REACT_APP_SOLSYSAUTH_API_URL";
  static clientId = process.env.REACT_APP_SOLSYSAUTH_CLIENT_ID || "RUNTIME_REPLACE_REACT_APP_SOLSYSAUTH_CLIENT_ID";

  static listScopes = (options = {}) => {
    return fetch(APIService.serverUrl + '/scope/list', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      credentials: "include",
      body: JSON.stringify({
        ...options.isActive && {isActive: true}
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw response.text();
        }
      })
      .catch(err => {
        console.error(err);
        alert("Fetching Scopes failed, please see logs for details")
      })
  }

  static listRoles = (options = {}) => {
    return fetch(APIService.serverUrl + "/role/list", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      credentials: "include",
      body: JSON.stringify({
        ...options.isActive && {isActive: true}
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw response.text();
        }
      })
      .catch(err => {
        console.error(err);
        alert('Listing role failed, please see logs for details');
      });
  }

  static listGrants = () => {
    return fetch(APIService.serverUrl + '/grant/list', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      credentials: "include"
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw response.text();
        }
      })
      .catch(err => {
        console.error(err);
        alert("Fetching Grants failed, please see logs for details")
      })
  }

  static getEnvironment = () => {
    return fetch(APIService.serverUrl + '/environment', {
      method: 'GET',
      headers: {
        clientId: APIService.clientId
      },
      credentials: "include"
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw response.text();
        }
      })
      .catch(err => {
        console.error(err);
        alert("Fetching Environment failed, please see logs for details")
      })
  }
}
