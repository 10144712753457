import React, {Component} from "react";
import {withRouter} from 'react-router';
import {Checkbox, Container, Form, Header, Button, Icon, Input} from "semantic-ui-react";
import APIService from "../API";


class EditRole extends Component {
  state = {
    role: null,

    name: "",
    isActive: true,

    isReadingRole: false,
    isUpdatingRole: false,
  };

  componentDidMount() {
    if(this.isEdit()) {
      this.readRole();
    };
  };

  isEdit = () => this.props.match.params.roleId !== undefined;

  roleId = () => this.props.match.params.roleId;

  readRole = () => {
    this.setState({isReadingRole: true});
    fetch(APIService.serverUrl + "/role/" + this.roleId(), {
      method: "GET",
      headers: {
        clientId: APIService.clientId
      },
      credentials: "include",
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw response.text();
        }
      })
      .then(json => {
        this.setState({
          role: json,
          name: json.name,
          isActive: json.isActive,
        })
      })
      .finally(() => {
        this.setState({isReadingRole: false});
      })
      .catch(err => {
        console.error(err);
        alert('Reading role failed, please see logs for details');
      });
  };

  updateRole = () => {
    this.setState({isUpdatingRole: true});
    fetch(APIService.serverUrl + "/role" + (this.isEdit() ? "/" + this.roleId() : ""), {
      method: this.isEdit() ? "PATCH" : "POST",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      credentials: "include",
      body: JSON.stringify({
        isActive: this.state.isActive,
        ...!this.isEdit() && { name: this.state.name }
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw response.text();
        }
      })
      .then((json) => {
        this.props.refreshList();
        if (this.isEdit()) {
          this.readRole();
        } else {
          this.props.history.replace('/role/edit/' + json._id);
          this.readRole();
        }
      })
      .finally(() => {
        this.setState({isUpdatingRole: false});
      })
      .catch(err => {
        console.error(err);
        alert('Updating role failed, please see logs for details');
      })
  };

  render = () => {

    return (
      <Container>
        <Form loading={this.state.isReadingRole}>
          <Header
            dividing
            icon='add'
            content={this.isEdit() ? 'Edit Role' : 'Add Role'}
          />

          {/*isActive Field*/}
          <Form.Field>
            <label>isActive:&nbsp;
              <Icon
                name='asterisk'
                size='small'
                color='blue'
              />
            </label>
            <Checkbox
              toggle
              id='isActive'
              name='isActive'
              disabled={this.state.isUpdatingRole}
              checked={this.state.isActive}
              onChange={(e) => {
                this.setState({isActive: !this.state.isActive})
              }}
            />
          </Form.Field>

          {/*Name Field*/}
          <Form.Field>
            <label>Name:&nbsp;
              <Icon
                name='asterisk'
                size='small'
                color='blue'
              />
            </label>
            <Input
              id='name'
              name='name'
              disabled={this.state.isUpdatingRole || this.isEdit()}
              value={this.state.name}
              onChange={(e) => {
                this.setState({name: e.target.value})
              }}
            />
          </Form.Field>

          {/*Button Field*/}
          <Form.Field>
            <Button
              size='mini'
              style={{marginRight: '10px'}}
              onClick={() => {this.props.history.goBack()}}
              loading={this.state.isUpdatingRole}
              disabled={this.state.isUpdatingRole || this.state.isReadingRole}
            >
              <Icon name='left arrow'/>
              Back
            </Button>

            <Button
                loading={this.state.isUpdatingRole}
                disabled={this.state.isUpdatingRole || this.state.isReadingRole}
                size='mini'
                color='green'
                onClick={this.updateRole}
            >
                <Icon name='right arrow' style={{marginRight: '5px', marginLeft: '0'}}/>
                {this.isEdit() ? 'Save Role' : 'Add Role'}
            </Button>
          </Form.Field>

        </Form>
      </Container>
    )
  };
};

export default withRouter(EditRole);
