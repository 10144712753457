import React, {Component} from "react";
import {withRouter} from "react-router";
import {Button, Container, Icon, Segment, Table} from "semantic-ui-react";
import { AppContext } from '../App';


class ListUsers extends Component {

  startAddUser = () => {
      this.props.history.push('/user/add');
  };

  startEditUser = (user) => {
      this.props.history.push('/user/edit/' + user._id)  ;
  };

  render = () => {
    let users = this.props.users
    return (
      <AppContext.Consumer>
        {context => (
          <Container>
            <span style={{ fontSize: "14px", fontStyle: 'italic' }}>Click The Rows Below To Edit Users.</span>
            {this.props.isFetching ?
              <Segment basic loading/>
              :
              <Table
                  celled
                  striped
                  selectable
                  compact='very'
                  size='small'
                  style={{marginTop: '5px'}}
              >
                <Table.Header fullWidth >
                  <Table.Row >
                    <Table.HeaderCell
                      id='listHeader'
                      textAlign="center"
                      verticalAlign='middle'
                      colSpan='12'
                      style={{padding: '7px'}}
                    >

                      <Button
                        id='addButton'
                        className='solsysButton'
                        onClick={this.startAddUser}
                        floated='right'
                        size='mini'
                      >
                        Add User
                      </Button>
                    </Table.HeaderCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell textAlign="center" collapsing>
                      <strong>Active</strong>
                    </Table.HeaderCell>
                    {context.isADActive &&
                      <Table.HeaderCell textAlign="center" collapsing>
                        <strong>Active Directory</strong>
                      </Table.HeaderCell>
                    }
                    {context.isLDAPActive &&
                      <Table.HeaderCell textAlign="center" collapsing>
                        <strong>LDAP</strong>
                      </Table.HeaderCell>
                    }
                    <Table.HeaderCell textAlign="center">
                      <strong>Username</strong>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {!users.length > 0 ?
                    null
                    :
                    users.map((user) => {
                      return (
                        <Table.Row
                          key={user._id.toString()}
                          id={user._id.toString()}
                          name={user.name}
                          style={{cursor: 'pointer'}}
                          onClick={(e) => {this.startEditUser(user)}}
                        >
                          <Table.Cell textAlign='center'>
                            {!user.isActive ? null :
                              <Icon name="check circle" color="green"/>
                            }
                          </Table.Cell>
                          {context.isADActive &&
                            <Table.Cell textAlign='center'>
                              {user.__t === 'ad-user' && <Icon name="check circle" color="green"/>}
                            </Table.Cell>
                          }
                          {context.isLDAPActive &&
                            <Table.Cell textAlign='center'>
                              {user.__t === 'ldap-user' && <Icon name="check circle" color="green"/>}
                            </Table.Cell>
                          }
                          <Table.Cell textAlign='center'>{user.username}</Table.Cell>
                        </Table.Row>
                      )
                    })
                  }
                </Table.Body>
              </Table>
            }
          </Container>
        )}
      </AppContext.Consumer>
    )
  };
};

export default withRouter(ListUsers);
