import React, { Component } from 'react';
import { Menu, Icon, Button } from 'semantic-ui-react';
import { withRouter } from "react-router";
import {SolsysAuthReactContext} from "@solsys/solsys-auth-react";


class HeaderMenu extends Component {

  render() {
    let pageName = '';
    let iconName = '';
    let iLoading = false;

    switch(this.props.location.pathname) {
      case "/client":
        pageName = 'Clients'; iconName = 'computer'; break
      case "/user":
        pageName = 'Users'; iconName = 'address book outline'; break
      case "/scope":
        pageName = 'Scopes'; iconName = 'unlock alternate'; break
      case "/role":
        pageName = 'Roles'; iconName = 'unlock'; break

      default:
          break
    };

    return (
      <SolsysAuthReactContext.Consumer>
        {(context) => {
          return(
            <Menu
                className='appMenu'
                pointing
                fixed='top'
                borderless
                compact
                key="header"
            >
              <div className='navDiv'>
                <div className='navContainer'>
                  <Button
                    id='navMenuButton'
                    className='solsysButton'
                    onClick={this.props.openNavMenu}
                  >
                    <Icon name='angle down'/>
                    <span style={{fontSize: '14px', color: '#fff'}}>Solsys Auth Admin</span>
                  </Button>
                  <Menu.Item header name='pageName' className='headerItem'>
                    <Icon loading={iLoading} name={iconName}/>
                    {pageName}
                  </Menu.Item>
                </div>

                <div className='signOutDiv'>
                  <Button
                    id='logoutButton'
                    className='solsysButton'
                    onClick={() => context.logout() }
                  >
                    <Icon name='log out'/>
                    <span style={{fontSize: '14px', color: '#fff'}}>Logout</span>
                  </Button>
                </div>
              </div>
            </Menu>
          )
        }}
      </SolsysAuthReactContext.Consumer>


    )
  }
}

export default withRouter(HeaderMenu);
