import React, { Component } from 'react';
import { Menu, Sidebar, Divider, Icon } from "semantic-ui-react";
import { withRouter } from 'react-router-dom';

class SideBarNavigation extends Component {

  onMenuItemClick = (e, { name }) => {
    switch (name) {
      case "clientList": this.props.history.push('/client'); break
      case "userList": this.props.history.push('/user'); break
      case "scopeList": this.props.history.push('/scope'); break
      case "roleList": this.props.history.push('/role'); break

      default: this.props.history.push('/');
    };
    this.props.closeNavMenu();
  }

  render() {
    let activeMenuItem = '';
    return (
      <Sidebar.Pushable className="sidebarPushable">
        <Sidebar
            borderless
            vertical
            as={Menu}
            key=""
            id="solsysMenu"
            className="menuSidebar"
            direction="left"
            animation='overlay'
            onHide={this.props.closeNavMenu}
            visible={this.props.isNavMenuVisible}
        >

          <Menu.Item disabled className='sideBarItem'>
            <h5>Solsys Auth Admin</h5>
          </Menu.Item>

          <Menu.Item
            name='clientList'
            active={activeMenuItem === 'clientList'}
            onClick={this.onMenuItemClick}
            className='sideBarItem'
          >
            <Icon name='computer'/>
            <h5>Clients</h5>
          </Menu.Item>
          <Menu.Item
            name='userList'
            active={activeMenuItem === 'userList'}
            onClick={this.onMenuItemClick}
            className='sideBarItem'
          >
            <Icon name='address book outline'/>
            <h5>Users</h5>
          </Menu.Item>

          <Menu.Item
            name='scopeList'
            active={activeMenuItem === 'scopeList'}
            onClick={this.onMenuItemClick}
            className='sideBarItem'
          >
            <Icon name='unlock alternate'/>
            <h5>Scopes</h5>
          </Menu.Item>

          <Menu.Item
            name='roleList'
            active={activeMenuItem === 'roleList'}
            onClick={this.onMenuItemClick}
            className='sideBarItem'
          >
            <Icon name='unlock'/>
            <h5>Roles</h5>
          </Menu.Item>

          <Divider/>

          <Menu.Item
            name='closeMenu'
            onClick={this.props.closeNavMenu}
            className='sideBarItem'
          >
            <Icon name='close' color='red'/>
            <h5>Close Menu</h5>
          </Menu.Item>

        </Sidebar>

        <Sidebar.Pusher dimmed={this.props.isNavMenuVisible}>
            {this.props.children}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
      )
    }
};

export default withRouter(SideBarNavigation);
