import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";

import SideBarNavigation from './SideBarNavigation';
import HeaderMenu from './HeaderMenu';
import ClientController from './Controllers/ClientController';
import ScopeController from './Controllers/ScopeController';
import RoleController from './Controllers/RoleController';
import UserController from './Controllers/UserController';

import APIService from './API'

import 'semantic-ui-css/semantic.min.css';
import './App.css';

export const AppContext = React.createContext();

class App extends Component {

  static defaultState = {
    isNavMenuVisible: false,
    isADActive: false,
    isLDAPActive: false,
  };

  state = App.defaultState;

  componentDidMount() {
    APIService.getEnvironment()
      .then(json => {
        this.setState({
          isADActive: json.isADActive,
          isLDAPActive: json.isLDAPActive
        })
      })
  }

  openNavMenu = () => {
    document.getElementsByTagName("body")[0].style = "overflow: hidden;"
    this.setState({isNavMenuVisible: true})
  }

  closeNavMenu = () => {
    document.getElementsByTagName("body")[0].style = ""
    this.setState({isNavMenuVisible: false})
  }

  render = () => {
    return (
      <div className="app">
        <AppContext.Provider value={{
          isADActive: this.state.isADActive,
          isLDAPActive: this.state.isLDAPActive
        }}>
          <SideBarNavigation
            isNavMenuVisible={this.state.isNavMenuVisible}
            openNavMenu={this.openNavMenu}
            closeNavMenu={this.closeNavMenu}
          >
            <HeaderMenu
              openNavMenu={this.openNavMenu}
            />

            <div className='appBody'>
              <Switch>
                <Route
                  path='/'
                  exact
                  render={(props) => {
                    props.history.push('/user')
                  }}
                />
                <Route
                  path='/scope'
                  render={(props) => {
                    return (
                      <ScopeController/>
                    )
                  }}
                />
                <Route
                  path='/role'
                  render={(props) => {
                    return (
                      <RoleController/>
                    )
                  }}
                />
                <Route
                  path='/client'
                  render={(props) => {
                    return (
                      <ClientController/>
                    )
                  }}
                />
                <Route
                  path='/user'
                  render={(props) => {
                    return (
                      <UserController/>
                    )
                  }}
                />
              </Switch>
            </div>

          </SideBarNavigation>
        </AppContext.Provider>
      </div>
    )
  }
}

export default withRouter(App);
