import React, {Component} from "react";
import {withRouter} from 'react-router';
import {Checkbox, Container, Dropdown, Form, Header, Button, Icon, Input, Label} from "semantic-ui-react";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";

import APIService from "../API";
import { AppContext } from '../App';


class EditUser extends Component {
  state = {
    user: null,

    isActive: true,
    isADAuth: false,
    isLDAPAuth: false,
    username: "",
    password: "",
    email: "",
    roles: [],
    additional: null,

    availableRoles: [],

    isReadingUser: false,
    isUpdatingUser: false,
    isFetchingRoles: false,
  };

  componentDidMount() {
    this.getRoles();
    if(this.isEdit()) {
      this.readUser();
    }
  };

  isEdit = () => this.props.match.params.userId !== undefined;

  userId = () => this.props.match.params.userId;

  readUser = () => {
    this.setState({isReadingUser: true});
    fetch(APIService.serverUrl + "/user/" + this.userId(), {
      method: "GET",
      headers: {
        clientId: APIService.clientId
      },
      credentials: "include",
    })
      .then(result => {
        return result.json();
      })
      .then(json => {
        this.setState({
          isActive: json.isActive,
          isADAuth: json.__t === 'ad-user',
          isLDAPAuth: json.__t === 'ldap-user',
          username: json.username,
          email: json.email,
          roles: json.roles,
          additional: JSON.stringify(json.additional, null, 2),
        })
      })
    this.setState({isReadingUser: false});
  };

  updateUser = () => {
    this.setState({isUpdatingUser: true});
    let additional
    try {
      additional = JSON.parse(this.state.additional)
    } catch (error) {
      alert("Could not parse the `Additional` object, please check your work and try again.")
      this.setState({isUpdatingUser: false})
      return
    }
    fetch(APIService.serverUrl + "/user" + (this.isEdit() ? "/" + this.userId() : ""), {
      method: this.isEdit() ? "PATCH" : "POST",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      credentials: "include",
      body: JSON.stringify({
        isActive: this.state.isActive,
        username: this.state.username,
        isADAuth: this.state.isADAuth,
        isLDAPAuth: this.state.isLDAPAuth,
        ...this.state.password !== "" &&
          !this.state.isADAuth &&
          !this.state.isLDAPActive &&
          {password: this.state.password},
        email: this.state.email,
        roles: this.state.roles,
        additional,
      })
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return null;
        }
      })
      .then((json) => {
        if (json) {
          this.props.refreshList();
          if (this.isEdit()) {
            this.readUser();
          } else {
            this.props.history.replace('/user/edit/' + json._id);
            this.readUser();
          }
        } else {
          console.log('patchUser PATCH Failed')
        }
        this.setState({isUpdatingUser: false});
      })
  };

  getRoles = () => {
    this.setState({isFetchingRoles: true})
    return APIService.listRoles({isActive: true})
      .then(json => {
        this.setState({availableRoles: json.results})
      })
      .finally(() => {
        this.setState({isFetchingRoles: false})
      });
  };

  render = () => {

    return (
      <AppContext.Consumer>
        {context => (
          <Container>
            <Form loading={this.state.isReadingUser}>
              <Header
                dividing
                icon='add'
                content={this.isEdit() ? 'Edit User' : 'Add User'}
              />

              {/*isActive Field*/}
              <Form.Field>
                <label>isActive:&nbsp;
                  <Icon
                    name='asterisk'
                    size='small'
                    color='blue'
                  />
                </label>
                <Checkbox
                  toggle
                  id='isActive'
                  name='isActive'
                  disabled={this.state.isUpdatingUser}
                  checked={this.state.isActive}
                  onChange={(e) => {
                    this.setState({isActive: !this.state.isActive})
                  }}
                />
              </Form.Field>

              {/*isADAuth Field*/}
              {context.isADActive &&
                <Form.Field>
                  <label>Active Directory Auth:&nbsp;
                    <Icon
                      name='asterisk'
                      size='small'
                      color='blue'
                    />
                  </label>
                  <Checkbox
                    toggle
                    id='isADAuth'
                    name='isADAuth'
                    disabled={this.state.isUpdatingUser || this.isEdit()}
                    checked={this.state.isADAuth}
                    onChange={(e) => {
                      this.setState({isADAuth: !this.state.isADAuth})
                    }}
                  />
                </Form.Field>
              }

              {/*isLDAPAuth Field*/}
              {context.isLDAPActive &&
                <Form.Field>
                  <label>LDAP Auth:&nbsp;
                    <Icon
                      name='asterisk'
                      size='small'
                      color='blue'
                    />
                  </label>
                  <Checkbox
                    toggle
                    id='isLDAPAuth'
                    name='isLDAPAuth'
                    disabled={this.state.isUpdatingUser || this.isEdit()}
                    checked={this.state.isLDAPAuth}
                    onChange={(e) => {
                      this.setState({isLDAPAuth: !this.state.isLDAPAuth})
                    }}
                  />
                </Form.Field>
              }

              {/*Username Field*/}
              <Form.Field>
                <label>Username:&nbsp;
                  <Icon
                    name='asterisk'
                    size='small'
                    color='blue'
                  />
                </label>
                <Input
                  id='username'
                  name='username'
                  disabled={this.state.isUpdatingUser || this.isEdit()}
                  value={this.state.username}
                  onChange={(e) => {
                    this.setState({username: e.target.value})
                  }}
                />
              </Form.Field>

              {/*Password Field*/}
              {!this.state.isADAuth && !this.state.isLDAPAuth &&
                <Form.Field>
                  <label>Password:&nbsp;
                    <Icon
                      name='asterisk'
                      size='small'
                      color='blue'
                    />
                  </label>
                  <Input
                    id='password'
                    name='password'
                    type='password'
                    disabled={this.state.isUpdatingUser}
                    value={this.state.password}
                    onChange={(e) => {
                      this.setState({password: e.target.value})
                    }}
                  />
                </Form.Field>
              }

              {/*Email Field*/}
              <Form.Field>
                <label>Email:&nbsp;
                  <Icon
                    name='asterisk'
                    size='small'
                    color='blue'
                  />
                </label>
                <Input
                  id='email'
                  name='email'
                  disabled={this.state.isUpdatingUser}
                  value={this.state.email}
                  onChange={(e) => {
                    this.setState({email: e.target.value})
                  }}
                />
              </Form.Field>

              {/*Roles Field*/}
              <Form.Group style={{display: 'flex', alignItems: 'flex-end'}}>
                <Form.Field style={{marginBottom: '-3px'}}>

                  <label>Roles:&nbsp;
                    <Icon
                      name='asterisk'
                      size='small'
                      color='blue'
                    />
                  </label>

                  <Dropdown
                    id='role'
                    name='role'
                    selection
                    multiple
                    loading={this.state.isFetchingRoles}
                    disabled={this.state.isUpdatingClient}
                    options={this.state.availableRoles.map(v => ({text: v.name, value: v.name}))}
                    value={this.state.roles}
                    onChange={(e, data) => {
                      this.setState({roles: data.value})
                    }}
                  />
                </Form.Field>
              </Form.Group>
              
              {/*Additional Field*/}

                  <label>Additional:</label>

                  <AceEditor
                    value={this.state.additional}
                    mode="json"
                    theme="github"
                    onChange={(additional) => {
                      this.setState({additional})
                    }}
                    name="UNIQUE_ID_OF_DIV"
                    editorProps={{ $blockScrolling: true }}
                  />

              {/*Button Field*/}
              <Form.Field>
                <Button
                  size='mini'
                  style={{marginRight: '10px'}}
                  onClick={() => {this.props.history.push("/user")}}
                  loading={this.state.isUpdatingUser}
                  disabled={this.state.isUpdatingUser || this.state.isReadingUser}
                >
                  <Icon name='left arrow'/>
                  Back
                </Button>

                <Button
                    loading={this.state.isUpdatingUser}
                    disabled={this.state.isUpdatingUser || this.state.isReadingUser}
                    size='mini'
                    color='green'
                    onClick={this.updateUser}
                >
                    <Icon name='right arrow' style={{marginRight: '5px', marginLeft: '0'}}/>
                    {this.isEdit() ? 'Save User' : 'Add User'}
                </Button>
              </Form.Field>
            </Form>
          </Container>
        )}
      </AppContext.Consumer>
    )
  };
};

export default withRouter(EditUser);
