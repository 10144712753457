import React, {Component} from "react";
import {withRouter} from "react-router";
import {Button, Container, Icon, Segment, Table} from "semantic-ui-react";


class ListClients extends Component {

  startAddClient = () => {
      this.props.history.push('/client/add');
  };

  startEditClient = (client) => {
      this.props.history.push('/client/edit/' + client._id)  ;
  };

  render = () => {
    let clients = this.props.clients
    return (
      <Container>
        <span style={{ fontSize: "14px", fontStyle: 'italic' }}>Click The Rows Below To Edit Clients.</span>
        {this.props.isFetching ?
          <Segment basic loading/>
          :
          <Table
              celled
              striped
              selectable
              compact='very'
              size='small'
              style={{marginTop: '5px'}}
          >
            <Table.Header fullWidth >
              <Table.Row >
                <Table.HeaderCell
                  id='listHeader'
                  textAlign="center"
                  verticalAlign='middle'
                  colSpan='12'
                  style={{padding: '7px'}}
                >

                  <Button
                    id='addButton'
                    className='solsysButton'
                    onClick={this.startAddClient}
                    floated='right'
                    size='mini'
                  >
                    Add Client
                  </Button>
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell textAlign="center" collapsing>
                  <strong>isActive</strong>
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  <strong>Client Name</strong>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {!clients.length > 0 ?
                null
                :
                clients.map((client) => {
                  return (
                    <Table.Row
                      key={client._id.toString()}
                      id={client._id.toString()}
                      name={client.name}
                      style={{cursor: 'pointer'}}
                      onClick={(e) => {this.startEditClient(client)}}
                    >
                      <Table.Cell textAlign='center'>
                        {client.isActive && <Icon name="check circle" color="green"/>}
                      </Table.Cell>
                      <Table.Cell textAlign='center'>{client.clientName}</Table.Cell>
                    </Table.Row>
                  )
                })
              }
            </Table.Body>
          </Table>
        }
      </Container>
    )
  };
};

export default withRouter(ListClients);
