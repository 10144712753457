import React, {Component} from "react";
import {withRouter} from "react-router";
import {Route, Switch} from "react-router-dom";

import APIService from "../API";
import ListRoles from '../Views/ListRoles';
import EditRole from '../Editors/EditRole'

class RoleController extends Component {

  state = {
    roles: [],
    isFetchingRoles: false,
  };

  componentDidMount() {
    this.getRoleList();
  };

  getRoleList = () => {
    this.setState({isFetchingRoles: true})
    return APIService.listRoles()
      .then(json => {
        this.setState({roles: json.results})
      })
      .finally(() => {
        this.setState({isFetchingRoles: false});
      })
  };

  render = () => {

    let relativePath = this.props.match.path;

    return (
      <Switch>
        <Route
          path={relativePath}
          exact
          render={() =>
            <ListRoles
              {...this.state}
              relativePath={relativePath}
              clients={this.state.roles}
              isFetching={this.state.isFetchingRoles}
            />
          }
        />
        <Route
          path={relativePath + "/add"}
          render={() =>
            <EditRole
              relativePath={relativePath}
              refreshList={this.getRoleList}
            />
          }
        />
        <Route
          path={relativePath + "/edit/:roleId"}
          render={() =>
            <EditRole
              relativePath={relativePath}
              refreshList={this.getRoleList}
            />
          }
        />
      </Switch>
    )
  };
};

export default withRouter(RoleController);
