import React, {Component} from "react";
import {withRouter} from "react-router";
import {Route, Switch} from "react-router-dom";

import APIService from "../API";
import ListClients from '../Views/ListClients';
import EditClient from '../Editors/EditClient'

class ClientController extends Component {

  state = {
    clients: [],
    isFetchingClients: false,
  };

  componentDidMount() {
    this.getClientList();
  };

  getClientList = () => {
    this.setState({isFetchingClients: true})
    fetch(APIService.serverUrl + "/client/list", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      credentials: "include"
    })
      .then(result => {
        return result.json();
      })
      .then(json => {
        this.setState({clients: json.results})
      })
    this.setState({isFetchingClients: false})
  };

  render = () => {

    let relativePath = this.props.match.path;

    return (
      <Switch>
        <Route
          path={relativePath}
          exact
          render={() =>
            <ListClients
              {...this.state}
              relativePath={relativePath}
              clients={this.state.clients}
              isFetching={this.state.isFetchingClients}
            />
          }
        />
        <Route
          path={relativePath + "/add"}
          render={() =>
            <EditClient
              relativePath={relativePath}
              refreshList={this.getClientList}
            />
          }
        />
        <Route
          path={relativePath + "/edit/:clientId"}
          render={() =>
            <EditClient
              relativePath={relativePath}
              refreshList={this.getClientList}
            />
          }
        />
      </Switch>
    )
  };
};

export default withRouter(ClientController);
